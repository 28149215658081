import { createElement } from "react";
import { Col, Row } from "../../../Components/UtilComponents.fs.js";
import { singleton as singleton_1, ofArray, empty } from "../fable_modules/fable-library.3.7.17/List.js";
import { append, singleton, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { RenderBreadcrumbs } from "../../../DesignSystems/DesignSystem.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossiers, quotes, customers } from "../AppRoutes.fs.js";
import { alignStart, TextColor, MarginTop, spaceBetween, MarginBottom, PaddingTop, fullWidth, PaddingRight, PaddingLeft } from "../../../Styles/Utils.fs.js";
import { join } from "../fable_modules/fable-library.3.7.17/String.js";
import { defaultArg } from "../fable_modules/fable-library.3.7.17/Option.js";

export function Breadcrumbs(breadcrumbsInputProps) {
    const currentPage = breadcrumbsInputProps.currentPage;
    return createElement(Row, {
        classes: empty(),
        children: toList(delay(() => ((currentPage.tag === 4) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Klant", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(customers)), 1)]],
            current: "Aanmaken",
        })) : ((currentPage.tag === 3) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Klant", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(customers)), 1)]],
            current: "Bewerken",
        })) : ((currentPage.tag === 2) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Klanten", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(customers)), 1)]],
            current: "Detail",
        })) : ((currentPage.tag === 5) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Dossiers",
        })) : ((currentPage.tag === 13) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Offertes",
        })) : ((currentPage.tag === 12) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Offertes", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(quotes)), 1)]],
            current: "Detail",
        })) : ((currentPage.tag === 8) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Dossiers", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(dossiers)), 1)]],
            current: "Aanmaken",
        })) : ((currentPage.tag === 7) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Dossiers", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(dossiers)), 1)]],
            current: "Bewerken",
        })) : ((currentPage.tag === 6) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Dossiers", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(dossiers)), 1)]],
            current: "Detail",
        })) : ((currentPage.tag === 0) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Login",
        })) : ((currentPage.tag === 9) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Public CIB Form",
        })) : ((currentPage.tag === 10) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "",
        })) : ((currentPage.tag === 11) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Vergoedingen",
        })) : ((currentPage.tag === 14) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Settings",
        })) : singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Klanten",
        })))))))))))))))))),
    });
}

export function Page(pageInputProps) {
    const children = pageInputProps.children;
    const actions = pageInputProps.actions;
    const title = pageInputProps.title;
    const currentPage = pageInputProps.currentPage;
    return createElement(Col, {
        classes: ofArray([PaddingLeft.L, PaddingRight.L, fullWidth, PaddingTop.L, MarginBottom.L]),
        children: toList(delay(() => append(singleton(createElement(Row, {
            classes: singleton_1(spaceBetween),
            children: ofArray([createElement(Col, {
                classes: empty(),
                children: ofArray([createElement(Breadcrumbs, {
                    currentPage: currentPage,
                }), createElement("h1", {
                    children: title,
                    className: join(" ", [MarginBottom.L, MarginTop.S, TextColor.Primary]),
                })]),
            }), createElement(Row, {
                classes: singleton_1(alignStart),
                children: toList(delay(() => actions)),
            })]),
        })), delay(() => children)))),
    });
}

export function PageCib(pageCibInputProps) {
    const children = pageCibInputProps.children;
    const className = pageCibInputProps.className;
    const actions = pageCibInputProps.actions;
    const title = pageCibInputProps.title;
    return createElement(Col, {
        classes: ofArray([PaddingLeft.L, PaddingRight.L, fullWidth, PaddingTop.L, defaultArg(className, "")]),
        children: toList(delay(() => append(singleton(createElement(Row, {
            classes: singleton_1(spaceBetween),
            children: ofArray([createElement(Col, {
                classes: empty(),
                children: singleton_1(createElement("h1", {
                    children: title,
                    className: join(" ", [MarginBottom.L, MarginTop.S, TextColor.Primary]),
                })),
            }), createElement(Row, {
                classes: singleton_1(alignStart),
                children: toList(delay(() => actions)),
            })]),
        })), delay(() => children)))),
    });
}

