import { Union } from "./fable_modules/fable-library.3.7.17/Types.js";
import { union_type, string_type } from "./fable_modules/fable-library.3.7.17/Reflection.js";
import { Match_routes, R_get_String, R__param_Z2F4DFF34, R$1__page_6CB7246, R_create, R__segment_Z721C83C5, R__page_1505 } from "../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { ofArray } from "./fable_modules/fable-library.3.7.17/List.js";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Login", "Customers", "CustomerDetail", "CustomerEdit", "CustomerCreate", "Dossiers", "DossierDetail", "DossierEdit", "DossierCreate", "PublicCibForm", "Compensations", "CompensationsEdit", "QuoteDetail", "Quotes", "Settings"];
    }
}

export function Page$reflection() {
    return union_type("AppRoutes.Page", [], Page, () => [[], [], [["id", string_type]], [["id", string_type]], [], [], [["id", string_type]], [["id", string_type]], [], [["code", string_type]], [], [], [["code", string_type]], [], []]);
}

export const login = R__page_1505(R__segment_Z721C83C5(R_create(), "login"), new Page(0));

export const index = R__page_1505(R_create(), new Page(5));

export const dossiers = R__page_1505(R__segment_Z721C83C5(R_create(), "dossiers"), new Page(5));

export const dossierCreate = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "dossiers"), "aanmaken"), new Page(8));

export const dossierEdit = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "dossiers"), "bewerken"), R_get_String()), (arg) => (new Page(7, arg)));

export const dossierDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "dossiers"), "detail"), R_get_String()), (arg) => (new Page(6, arg)));

export const settings = R__page_1505(R__segment_Z721C83C5(R_create(), "settings"), new Page(14));

export const customers = R__page_1505(R__segment_Z721C83C5(R_create(), "customers"), new Page(1));

export const customerCreate = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "customers"), "aanmaken"), new Page(4));

export const customerEdit = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "customers"), "bewerken"), R_get_String()), (arg) => (new Page(3, arg)));

export const customerDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "customers"), "detail"), R_get_String()), (arg) => (new Page(2, arg)));

export const publicCibForm = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R_create(), "cib"), R_get_String()), (arg) => (new Page(9, arg)));

export const compensations = R__page_1505(R__segment_Z721C83C5(R_create(), "compensation"), new Page(10));

export const compensationEdit = R__page_1505(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "compensation"), "bewerken"), new Page(11));

export const quotes = R__page_1505(R__segment_Z721C83C5(R_create(), "quotes"), new Page(13));

export const quoteDetail = R$1__page_6CB7246(R__param_Z2F4DFF34(R__segment_Z721C83C5(R__segment_Z721C83C5(R_create(), "quotes"), "detail"), R_get_String()), (arg) => (new Page(12, arg)));

export function mainRouter(url) {
    return Match_routes(ofArray([login, publicCibForm]), url);
}

export function appRouter(url) {
    return Match_routes(ofArray([index, customers, customerCreate, customerEdit, customerDetail, dossiers, dossierCreate, dossierEdit, dossierDetail, compensations, compensationEdit, quotes, quoteDetail, settings]), url);
}

