import { keyValueList } from "../fable_modules/fable-library.3.7.17/MapUtil.js";
import { Union, Record } from "../fable_modules/fable-library.3.7.17/Types.js";
import { union_type, bool_type, record_type, string_type, int32_type } from "../fable_modules/fable-library.3.7.17/Reflection.js";
import * as react from "react";
import react_signature_canvas from "react-signature-canvas";

function toObj(p) {
    return keyValueList(p, 1);
}

export class CanvasProps extends Record {
    constructor(width, height, className) {
        super();
        this.width = (width | 0);
        this.height = (height | 0);
        this.className = className;
    }
}

export function CanvasProps$reflection() {
    return record_type("SignatureCanvas.CanvasProps", [], CanvasProps, () => [["width", int32_type], ["height", int32_type], ["className", string_type]]);
}

export class SignaturePadOptions extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CanvasProps", "ClearOnResize", "IsEmpty"];
    }
}

export function SignaturePadOptions$reflection() {
    return union_type("SignatureCanvas.SignaturePadOptions", [], SignaturePadOptions, () => [[["Item", CanvasProps$reflection()]], [["Item", bool_type]], [["Item", bool_type]]]);
}

export function SignatureCanvas(props) {
    const props_1 = toObj(props);
    return react.createElement(react_signature_canvas, props_1);
}

