import { useSnackbar } from "notistack";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { useReact_useRef_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { PartnerPortalSettingsPageContainer, SignatureBox } from "../../../Styles/SignaturePageStyles.fs.js";
import { SignaturePadOptions, SignatureCanvas, CanvasProps } from "../bindings/SignatureCanvas.fs.js";
import { createElement } from "react";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { singleton, ofArray, empty } from "../fable_modules/fable-library.3.7.17/List.js";
import { MarginRight, MarginTop, fullWidth } from "../../../Styles/Utils.fs.js";
import { createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { isNullOrWhiteSpace, printf, toConsole, join } from "../fable_modules/fable-library.3.7.17/String.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { Page } from "../components/Page.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";

export function PartnerSignaturePage(api) {
    let elems_1;
    const snackbar = useSnackbar();
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetPartnerSignature(), []);
    const isSaving = useToggle(false);
    const signatureCanvasRef = useReact_useRef_1505(void 0);
    const canvasProps = new CanvasProps(600, 500, SignatureBox(useToggle(true).State));
    return createElement(Col, {
        classes: empty(),
        children: ofArray([createElement(Col, {
            classes: ofArray([fullWidth, PartnerPortalSettingsPageContainer]),
            children: ofArray([createElement("h4", {
                children: ["Signature Template"],
            }), SignatureCanvas([["ref", signatureCanvasRef], new SignaturePadOptions(0, canvasProps)])]),
        }), createElement(Col, {
            classes: singleton(MarginTop.M),
            children: singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [MuiHelpers_createElement(Button, [["className", join(" ", [MarginRight.M])], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_1) => {
                window.location.reload();
            }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]]), MuiHelpers_createElement(Button, [["variant", "contained"], ["color", "primary"], ["size", "medium"], ["disabled", isSaving.State], ["children", "BEWAREN"], ["onClick", (_arg_2) => {
                const matchValue = signatureCanvasRef.current;
                if (matchValue == null) {
                    toConsole(printf("None"));
                }
                else {
                    const signatureCanvas = matchValue;
                    isSaving.On();
                    const signatureBase64 = signatureCanvas.getCanvas().toDataURL("image/png");
                    startImmediate(singleton_1.Delay(() => {
                        toConsole(printf("save settings"));
                        return singleton_1.Bind(api.SaveSettings(signatureBase64), (_arg) => {
                            const res = _arg;
                            isSaving.Off();
                            if (res.tag === 1) {
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, res.fields[0], SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton_1.Zero();
                            }
                            else {
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, res.fields[0], SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                window.location.reload();
                                return singleton_1.Zero();
                            }
                        });
                    }));
                    window.scrollTo(0, 0);
                }
            }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))),
        })]),
    });
}

export function SettingsForm(settingsFormInputProps) {
    const api = settingsFormInputProps.api;
    const currentPage = settingsFormInputProps.currentPage;
    const showSavePage = useToggle(false);
    const snackbar = useSnackbar();
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetPartnerSignature(), []);
    const editButton = MuiHelpers_createElement(Button, [["variant", "contained"], ["color", "secondary"], ["size", "medium"], ["children", "Bewerken"], ["onClick", (_arg) => {
        showSavePage.On();
    }]]);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Settings",
        actions: [editButton],
        children: [createElement(DisplayDeferred, {
            data: data,
            view: (partnerSignatureImage) => createElement(Row, {
                classes: empty(),
                children: toList(delay(() => (isNullOrWhiteSpace(partnerSignatureImage) ? singleton_2(createElement(PartnerSignaturePage, api)) : (showSavePage.State ? singleton_2(createElement(PartnerSignaturePage, api)) : singleton_2(createElement(Col, {
                    classes: empty(),
                    children: ofArray([createElement("h4", {
                        children: ["Signature Template"],
                    }), createElement("img", {
                        src: partnerSignatureImage,
                        className: join(" ", [SignatureBox(true)]),
                    })]),
                })))))),
            }),
        })],
    });
}

