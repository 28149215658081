import { Record } from "../fable_modules/fable-library.3.7.17/Types.js";
import { obj_type, record_type, class_type, string_type } from "../fable_modules/fable-library.3.7.17/Reflection.js";
import { fromParts, op_Addition } from "../fable_modules/fable-library.3.7.17/Decimal.js";
import Decimal from "../fable_modules/fable-library.3.7.17/Decimal.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { printf, toText } from "../fable_modules/fable-library.3.7.17/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { singleton, map, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { createElement } from "react";
import { Row } from "../../../Components/UtilComponents.fs.js";
import { TextSize, MarginTop } from "../../../Styles/Utils.fs.js";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.17/Option.js";
import { Compensation_compensationToDecimal } from "../../../shared/Format.fs.js";

export class SampleData extends Record {
    constructor(Type, PricePerSample, Compensation) {
        super();
        this.Type = Type;
        this.PricePerSample = PricePerSample;
        this.Compensation = Compensation;
    }
}

export function SampleData$reflection() {
    return record_type("PriceListModal.SampleData", [], SampleData, () => [["Type", string_type], ["PricePerSample", class_type("System.Decimal")], ["Compensation", class_type("System.Decimal")]]);
}

export class PropertyData extends Record {
    constructor(Type, StandardPrice, Compensation) {
        super();
        this.Type = Type;
        this.StandardPrice = StandardPrice;
        this.Compensation = Compensation;
    }
}

export function PropertyData$reflection() {
    return record_type("PriceListModal.PropertyData", [], PropertyData, () => [["Type", string_type], ["StandardPrice", obj_type], ["Compensation", obj_type]]);
}

export function SampleTableRow(data) {
    let elements;
    const total = op_Addition(data.PricePerSample, data.Compensation);
    return MuiHelpers_createElement(TableRow, [(elements = [MuiHelpers_createElement(TableCell, [["children", data.Type]]), MuiHelpers_createElement(TableCell, [["align", "right"], ["children", toText(printf("%.2f"))(data.PricePerSample)]]), MuiHelpers_createElement(TableCell, [["align", "right"], ["children", toText(printf("%.2f"))(data.Compensation)]]), MuiHelpers_createElement(TableCell, [["align", "right"], ["children", toText(printf("%.2f"))(total)]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
}

export function PropertyTypeRow(data) {
    let elements;
    let total;
    const matchValue = [data.StandardPrice, data.Compensation];
    let pattern_matching_result;
    if (matchValue[0] instanceof Decimal) {
        if (matchValue[1] instanceof Decimal) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const arg = op_Addition(matchValue[0], matchValue[1]);
            total = toText(printf("%.2f"))(arg);
            break;
        }
        case 1: {
            total = "op aanvraag";
            break;
        }
    }
    const formatValue = (value) => {
        if (value instanceof Decimal) {
            return toText(printf("%.2f"))(value);
        }
        else {
            return "op aanvraag";
        }
    };
    return MuiHelpers_createElement(TableRow, [(elements = [MuiHelpers_createElement(TableCell, [["children", data.Type]]), MuiHelpers_createElement(TableCell, [["align", "right"], ["children", formatValue(data.StandardPrice)]]), MuiHelpers_createElement(TableCell, [["align", "right"], ["children", formatValue(data.Compensation)]]), MuiHelpers_createElement(TableCell, [["align", "right"], ["children", total]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
}

export function DisplayModal(displayModalInputProps) {
    let elements_10, children, elements_3, elements_1, elements, elements_2, elements_7, elements_5, elements_4, elements_6, children_1;
    const propertyCompensation = displayModalInputProps.propertyCompensation;
    const onClose = displayModalInputProps.onClose;
    const isOpen = displayModalInputProps.isOpen;
    const sampleCompensation = fromParts(0, 0, 0, false, 0);
    const sampleData = ofArray([new SampleData("Puntstaal", fromParts(20, 0, 0, false, 0), sampleCompensation), new SampleData("Mengstaal", fromParts(20, 0, 0, false, 0), sampleCompensation)]);
    const propertyData = ofArray([new PropertyData("Studio/éénkamerappartement", fromParts(37500, 0, 0, false, 2), propertyCompensation), new PropertyData("Appartement", fromParts(40000, 0, 0, false, 2), propertyCompensation), new PropertyData("Woonhuis", fromParts(47000, 0, 0, false, 2), propertyCompensation), new PropertyData("Garage/garagebox", fromParts(18000, 0, 0, false, 2), propertyCompensation), new PropertyData("Gemeenschappelijke delen", "op aanvraag", "op aanvraag"), new PropertyData("Bedrijfspanden, handelspanden", "op aanvraag", "op aanvraag"), new PropertyData("Meergezinswoningen", "op aanvraag", "op aanvraag")]);
    return MuiHelpers_createElement(Dialog, [["fullWidth", true], ["maxWidth", "md"], ["open", isOpen], ["onClose", (_arg_1, v) => {
        onClose(false);
    }], (elements_10 = [MuiHelpers_createElement(DialogTitle, [["children", "Vergoedingen asbestinventarissen"]]), (children = ofArray([MuiHelpers_createElement(Typography, [["style", {
        fontWeight: "bolder",
    }], ["variant", "subtitle1"], ["children", "Stalen"]]), MuiHelpers_createElement(Table, [(elements_3 = [MuiHelpers_createElement(TableHead, [(elements_1 = [MuiHelpers_createElement(TableRow, [(elements = [MuiHelpers_createElement(TableCell, [["children", ""]]), MuiHelpers_createElement(TableCell, [["style", {
        fontWeight: "bold",
    }], ["align", "right"], ["children", "Prijs per staal excl. BTW"]]), MuiHelpers_createElement(TableCell, [["style", {
        fontWeight: "bold",
    }], ["align", "right"], ["children", "Vergoeding excl. BTW"]]), MuiHelpers_createElement(TableCell, [["style", {
        fontWeight: "bold",
    }], ["align", "right"], ["children", "Totaal per staal excl. BTW"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]), MuiHelpers_createElement(TableBody, [(elements_2 = map((data) => createElement(SampleTableRow, data), sampleData), ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]), MuiHelpers_createElement(Typography, [["style", {
        marginTop: 24 + "px",
        fontWeight: "bolder",
    }], ["variant", "subtitle1"], ["children", "Type vastgoed"]]), MuiHelpers_createElement(Table, [(elements_7 = [MuiHelpers_createElement(TableHead, [(elements_5 = [MuiHelpers_createElement(TableRow, [["style", {
        fontWeight: "bold",
    }], (elements_4 = [MuiHelpers_createElement(TableCell, [["children", ""]]), MuiHelpers_createElement(TableCell, [["style", {
        fontWeight: "bold",
    }], ["align", "right"], ["children", "Standaard prijs excl. BTW"]]), MuiHelpers_createElement(TableCell, [["style", {
        fontWeight: "bold",
    }], ["align", "right"], ["children", "Vergoeding excl. BTW"]]), MuiHelpers_createElement(TableCell, [["style", {
        fontWeight: "bold",
    }], ["align", "right"], ["children", "Totaal excl. BTW"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])]), MuiHelpers_createElement(TableBody, [(elements_6 = map((data_1) => createElement(PropertyTypeRow, data_1), propertyData), ["children", Interop_reactApi.Children.toArray(Array.from(elements_6))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_7))])]), createElement(Row, {
        classes: ofArray([MarginTop.M, TextSize.Xxs]),
        children: singleton("inclusief 2 verplichte staalnames van de pleisterwerken en OVAM retributie"),
    })]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton(MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        onClose(false);
    }], ["children", "Sluiten"]])), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_10))])]);
}

export function PriceListModal(priceListModalInputProps) {
    const api = priceListModalInputProps.api;
    const onClose = priceListModalInputProps.onClose;
    const isOpen = priceListModalInputProps.isOpen;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCompensationAmount(), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (compensation) => {
            const comp = defaultArg(map_1(Compensation_compensationToDecimal, compensation), fromParts(20, 0, 0, false, 0));
            return createElement(DisplayModal, {
                isOpen: isOpen,
                onClose: onClose,
                propertyCompensation: comp,
            });
        },
    });
}

